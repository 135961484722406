<script>
import Banner from "@/views/components/Banner.vue";
import { tabsOption } from "@/views/supportSystem/moke";
import Breadcrumb from "@/views/components/Breadcrumb.vue";
import NotData from "@/views/components/Not_data.vue";

export default {
  name: "index",
  components: { NotData, Breadcrumb, Banner },
  data() {
    return {
      tabsOption: tabsOption,
      currentTab: {}, // 当前选中的标签页数据
      breadcrumbOption: [], // 面包屑
      childSelect: {}, // 页面选中的节点
      showData: {} // 要显示得数据对象
    };
  },
  mounted() {
    this.currentTab = this.tabsOption[0];
    this.childSelect = this.tabsOption[0];
    this.checkTab(this.currentTab.key);
  },
  methods: {
    /**
     * 切换一级目录
     * @param key
     */
    checkTab(key) {
      this.childSelect = {};
      this.showData = {};
      // 初始化三级目录
      const tabsOption = this.tabsOption;
      tabsOption.map(item => {
        if (item.key === key) {
          this.currentTab = item;

          // 添加面包屑
          const currentOption = [
            {
              key: "supportSystem",
              toPath: "/supportSystem"
            }
          ];
          currentOption.push({
            key: item.key,
            title: item.title,
            toPath: "/supportSystem"
          });
          this.breadcrumbOption = currentOption;
          this.checkChildSelect(item);
        }
      });
    },
    /**
     * 切换二级，三级目录保存数据
     * @param item
     * @param menuCount 面包屑的位数
     */
    checkChildSelect(item, menuCount) {
      // 添加面包屑 （暂时关闭）start
      const currentOption = this.breadcrumbOption;
      const isFlog = currentOption.some(o => o.key === item.key);
      if (!isFlog) {
        const newOption = {
          key: item.key,
          title: item.title,
          toPath: "/supportSystem?sel=" + item.key
        };
        currentOption.splice(menuCount, menuCount + 1, newOption);
        this.breadcrumbOption = currentOption;
      }
      // 添加面包屑 end
      // 没有三级目录，三级目录不需要选中当前的菜单节点
      if (item.menuChild && item.menuChild.length === 0) {
        this.childSelect = item.menuChild[0]; // 设置当前选中的内容
        this.showData = item;
      } else {
        // 清除数据
        this.childSelect = item;
        this.showData = item;
      }
    }
  }
};
</script>

<template>
  <div>
    <Banner
      :bannerSrc="require('@/assets/banner/support.jpg')"
      :tabs="tabsOption"
      :selTab="currentTab.key"
    />
    <div class="padding-comm-warp _content_warp news support_warp">
      <Breadcrumb :breadcrumbList="breadcrumbOption" />
      <div class="content">
        <el-image :src="require('@/assets/no_data.png')" fit="scale-down" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../index.scss";
.support_warp {
  display: block;
}
</style>
